







































































import { Component, Ref, Vue } from "vue-property-decorator";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

import {
  InternalMonthlySummarizeDto,
  OrganizationUnitDto,
  AttachmentHostType,
  AuditFlowScope,
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import FileList from "@/components/CustomForm/TableDetail/components/fileList.vue";

@Component({
  name: "internalMonthlySummarizeDetail",
  components: {
    FileList,
    AttachmentsView,
    ExportWord,
    AuditBlock,
  },
  filters: {
    formatStatus(value: string, list: any[]) {
      var label = "";
      if (list && list.length > 0) {
        list.map((item: any) => {
          if (item.value === value) {
            label = item.label;
          }
        });
      }
      return label;
    },
  },
})
export default class internalMonthlySummarizeDetail extends Vue {

  orgId?: number;
  detail: InternalMonthlySummarizeDto = {};
  unitList?: OrganizationUnitDto[] = [];
  selector = "table-detail";
  filename = "项目月总结表";
  styles = "";
  statusList = [
    {
      value: "None",
      label: "未展开",
    },
    {
      value: "Adjust",
      label: "调整",
    },
    {
      value: "Complete",
      label: "已完成",
    },
  ];
  get hostTypeFile() {
    return AttachmentHostType.Internal_Project_Monthly_Summarize_Attachment;
  }


  created() {
    if (this.$route.params.id) {
      this.orgId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  get auditApi(){
    return {
      canAudit: api.internalMonthlySummarize.canAudit,
      userAudit: api.internalMonthlySummarize.audit } as AuditApi
  }

  // 获取详情
  async fetchDetail() {
    //合作方列表
    api.organizationUnit.getAll({ maxResultCount: 100 }).then((res: any) => {
      this.unitList = res.items!;
    });

    await api.internalMonthlySummarize.get({ id: this.orgId }).then((res) => {
      this.detail = { ...res };
    });
  }

  formatPartnerOrgName(orgIds: string) {
    const displayNames: string[] = [];
    this.unitList!.map((item: any) => {
      if (orgIds.indexOf(item.id) > -1) {
        displayNames.push(item.displayName);
      }
    });
    return displayNames.join(",");
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
