




























































import { Component, Ref, Vue } from "vue-property-decorator";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import {
  ExternalProjectChangeApplyDto,
  OrganizationUnitDto,
  AttachmentHostType,
  AuditFlowScope,
  DataDictionaryDto,
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";


@Component({
  name: "ExternalProjectChangeApplyDetail",
  components: {
    AttachmentsView,
    AuditBlock,
  },
})
export default class ExternalProjectChangeApplyDetail extends Vue {

  dataId?: number;
  changeApplyTypeList: DataDictionaryDto[] = [];
  detail: ExternalProjectChangeApplyDto = {
    /**  */
    projectId: undefined,

    /**  */
    project: {},

    /**  */
    sourceExternalProjectId: undefined,

    /**  */
    sourceExternalProject: {},

    /**  */
    targetExternalProjectId: undefined,

    /**  */
    targetExternalProject: {},

    /** 变更类别,多选，数据字典(经费变更\/项目延期\/内容变更\/目标人群变更\/其他变) */
    changeTypes: undefined,

    /** 变更时间 */
    changeDate: undefined,

    /** 变更原因 */
    changeReason: undefined,

    /** 变更内容 */
    changeContent: undefined,

    organizationUnit: {},

    /**  */
    id: undefined,
  };
  hostType = AttachmentHostType.Internal_Personal_Year_End_Summary;


  get auditApi(){
    return {
      canAudit: api.externalProjectChangeApply.canAudit,
      userAudit: api.externalProjectChangeApply.audit} as AuditApi
  }


  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    //
    api.dataDictionary
      .getDataDictionaryListByKey({ key: "ProjectChangeType" })
      .then((res) => {
        this.changeApplyTypeList = res.items!;
      });

    await api.externalProjectChangeApply
      .get({ id: this.dataId })
      .then((res) => {
        this.detail = { ...res };
      });
  }

  handleProjectDetail(id: any) {
    this.$router.push({
      name: "externalProjectProposal-detail",
      params: {
        id: id,
      },
    });
  }

  formatChangeType(str: string) {
    const displayNames: string[] = [];
    this.changeApplyTypeList!.map((item: any) => {
      if (str.indexOf(item.key) > -1) {
        displayNames.push(item.displayName);
      }
    });
    return displayNames.join(",");
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
