












































































import { Component, Ref, Vue } from "vue-property-decorator";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

import {
  InternalMonthlySummarizeDto,
  OrganizationUnitDto,
  ExternalProjectProcessMonitoringDto,
  AttachmentHostType,
  AuditFlowScope,
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
@Component({
  name: "externalProjectProcessMonitoringDetail",
  components: {
    AttachmentsView,
    ExportWord,
    AuditBlock,
  },
})
export default class externalProjectProcessMonitoringDetail extends Vue {

  recordId?: number;
  detail: ExternalProjectProcessMonitoringDto = {};
  selector = "table-detail";
  filename = "项目监测记录表";
  styles = "";

  partnerOrgList: OrganizationUnitDto[] = [
    {
      displayName: "合作方1",
      id: 1,
    },
    {
      displayName: "合作方2",
      id: 2,
    },
    {
      displayName: "合作方3",
      id: 3,
    },
    {
      displayName: "合作方4",
      id: 4,
    },
  ];


  get hostTypeFile() {
    return AttachmentHostType.External_Project_Process_Monitoring;
  }
  created() {
    if (this.$route.params.id) {
      this.recordId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }


  get auditApi(){
    return {
      canAudit: api.externalProjectProcessMonitoringService.canAudit,
      userAudit: api.externalProjectProcessMonitoringService.audit
    } as AuditApi
  }

  // 获取详情
  async fetchDetail() {
    await api.externalProjectProcessMonitoringService
      .getDetail({ id: this.recordId })
      .then((res) => {
        this.detail = { ...res };
      });
  }


  // 返回
  private cancel() {
    this.$router.back();
  }
}
