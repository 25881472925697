































































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import moment from "moment";
import {
  ExternalProjectChangeApplyDto,
  AuditFlowScope,
  DataDictionaryDto,
} from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "ExternalProjectChangeApplyList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
  },
  filters: {
    formatDate(year: any, month: any) {
      if (month < 10) {
        month = "0" + month;
      }
      return year + "-" + month;
    },
  },
})
export default class ExternalProjectChangeApplyList extends Vue {
  queryForm: any = {
    displayName: "",
    changeTypes: undefined,
  };
  changeApplyTypeList: DataDictionaryDto[] = [];

  created() {
    //
    api.dataDictionary
      .getDataDictionaryListByKey({ key: "ProjectChangeType" })
      .then((res) => {
        this.changeApplyTypeList = res.items!;
      });
  }

  fetchData(params: any) {
    return api.externalProjectChangeApply.getAll(params);
  }

  // 操作按钮
  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;

      case "edit":
        this.handleEdit($row.index, $row.item);
        break;

      case "delete":
        this.handleDelete($row.index, $row.item);
        break;

      case "startAudit":
        this.handleStartAudit($row.index, $row.item);
        break;

      case "auditFlow":
        this.handleAuditFlow($row.index, $row.item);
        break;
    }
  }

  handleCreate() {
    this.$router.push({
      name: "externalProjectChangeApplyCreate",
    });
  }

  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "externalProjectChangeApplyEdit",
      params: {
        id: row.id,
      },
    });
  }

  async handleStartAudit(index: number, row: ExternalProjectChangeApplyDto) {
    // await api.externalProjectChangeApply
    //   .startAudit({ body: { id: row.id } })
    //   .then(res => {
    //     this.$message.success("已提交");
    //   });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.ExternalProjectAdjustment,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }

  handleDetail(row: any) {
    this.$router.push({
      name: "externalProjectChangeApplyDetail",
      params: {
        id: row.id,
      },
    });
  }

  // 删除
  async handleDelete(index: number, row: ExternalProjectChangeApplyDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.externalProjectChangeApply
        .delete({ id: row.id })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }

  formatChangeType(str: string) {
    const displayNames: string[] = [];
    this.changeApplyTypeList!.map((item: any) => {
      if (str.indexOf(item.key) > -1) {
        displayNames.push(item.displayName);
      }
    });
    return displayNames.join(",");
  }
}
