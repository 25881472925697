




























































import { Component, Vue } from "vue-property-decorator";
import {
  NotifyRecordDetailDto,
  NotifyRecordDto,
  NotifyTemplateDto,
  OrganizationUnitShortDto,
  ProjectDto,
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import ExportWord from "@/components/DownloadWord/index.vue";

@Component({
  name: "NotifyRecordListDetail",
  components: {
    ExportWord,
  },
  filters: {
    formatNotifyType(type: string, list: any[]) {
      let NotifyTypeName = "";
      if (list) {
        list.map((res: any) => {
          if (res.value === type) {
            NotifyTypeName = res.text;
            return;
          }
        });
      }
      return NotifyTypeName;
    },
  },
})
export default class NotifyRecordListDetail extends Vue {
  dataId?: number;
  project: ProjectDto = {};
  organizationUnit: OrganizationUnitShortDto = {};
  notifyTemplate: NotifyTemplateDto = {};
  detail: NotifyRecordDetailDto = {
    project: this.project,
    organizationUnit: this.organizationUnit,
    notifyTemplate: this.notifyTemplate,
  };
  NotifyTypeList = [];
  selector = "table-detail";
  filename = "项目评估计划通知";
  styles = "";

  async created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      await this.fetchNotifyTypeList();
      this.fetchDetail();
    }
  }

  // 获取通知类型
  fetchNotifyTypeList() {
    api.enumService
      .getValues({ typeName: "NotifyTemplateType" })
      .then((res: any) => {
        this.NotifyTypeList = res;
      });
  }

  // 获取详情
  async fetchDetail() {
    await api.notifyRecord.getDetail({ id: this.dataId }).then((res) => {
      this.detail = res;
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
