

































































































































































































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import moment from "moment";
import { ExternalProjectIntentionDto, AuditFlowScope } from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import ProjectUploadTitleImage from "@/views/project/common/UploadTitleImage.vue";
import ProjectPublishButton from "@/views/project/common/ProjectPublishButton.vue";
import BudgetItemsEdit from "@/views/project/common/ExternalBudgetItemsEdit.vue";
import ProjectSubmitDateChangeEdit from "@/views/project/externalProject/proposal/submitDateChangeEdit.vue";
import ProjectTypeEdit from "@/views/project/common/ProjectTypeEdit.vue";
import ProjectManagerEdit from "@/views/project/common/ProjectManagerEdit.vue";

// 计划书管理
@Component({
  name: "externalProjectProposal",
  components: {
    ProjectManagerEdit,
    ProjectTypeEdit,
    ProjectSubmitDateChangeEdit,
    ProjectPublishButton,
    ProjectUploadTitleImage,
    PagedTableView,
    AbSelect,
    AgileAuditTag,
    BudgetItemsEdit,
  },
})
export default class externalProjectProposal extends Vue {
  queryForm: any = {
    displayName: "",
    orgName: "",
    year: undefined,
  };

  projectId = 0;

  created() {
    if (this.$route.query.projectId) {
      this.queryForm.projectId = this.projectId = Number(
        this.$route.query.projectId
      );
    }
  }

  fetchData(params: any) {
    return api.externalProject.getAll(params);
  }

  // 操作按钮
  handleMenu($row: any) {
    this.projectId = $row.item.project.id;
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;

      case "edit":
        this.handleEdit($row.index, $row.item);
        break;

      case "delete":
        this.handleDelete($row.index, $row.item);
        break;

      case "startAudit":
        this.handleStartAudit($row.index, $row.item);
        break;

      case "auditFlow":
        this.handleAuditFlow($row.index, $row.item);
        break;
      case "titleImage":
        (this.$refs.projectUploadTitleImage as any).show = true;
        break;
      case "updateBudgetItems":
        (this.$refs.budgetItemsEdit as any).show = true;
        break;
      case "publishControl":
        api.project
          .changePublicShow({ projectId: this.projectId })
          .then((res) => {
            this.$message.success("是否公示已更新");
          });
        break;
      case "changeProjectReportSubmitDate":
        (this.$refs.projectSubmitDateChangeEdit as any).show = true;
        break;
      case "updateProjectType":
        (this.$refs.projectTypeEdit as any).show = true;
        break;

      case "updateProjectManager":
        (this.$refs.projectManagerEdit as any).show = true;
        break;
      case "editProjectResult":
        this.$router.push({
          name: "editProjectResult",
          params: {
            projectId: this.projectId.toString(),
          },
        });
        break;
    }
  }

  handleCreate() {
    this.$router.push({
      name: "externalProjectProposal-create",
    });
  }

  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "externalProjectProposal-edit",
      params: {
        id: row.id,
      },
    });
  }

  handleDetail(row: any) {
    this.$router.push({
      name: "externalProjectProposal-detail",
      params: {
        id: row.id,
      },
    });
  }

  //  提交审核
  async handleStartAudit(index: number, row: ExternalProjectIntentionDto) {
    // await api.externalProject.startAudit({body: {id: row.id}}).then(res => {
    //   this.$message.success("已提交");
    // });
  }

  // 删除
  async handleDelete(index: number, row: ExternalProjectIntentionDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.externalProject.delete({ id: row.id }).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.ExternalProject,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }
}
