var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('paged-table-view',{ref:"pagedTableView",attrs:{"search-function":_vm.fetchData,"query-form":_vm.queryForm},scopedSlots:_vm._u([{key:"condition",fn:function(){return [_c('el-form-item',[_c('el-input',{attrs:{"clearable":"","type":"text","placeholder":"通知标题"},model:{value:(_vm.queryForm.title),callback:function ($$v) {_vm.$set(_vm.queryForm, "title", $$v)},expression:"queryForm.title"}})],1)]},proxy:true},{key:"buttons",fn:function(){return [_c('el-divider',{staticClass:"query-form-divider"}),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:('Pages.NotifyRecordOrganizationInvestigation:Create'),expression:"'Pages.NotifyRecordOrganizationInvestigation:Create'"}],staticClass:"margin-bottom-20",attrs:{"type":"success"},on:{"click":_vm.handleCreate}},[_c('i',{staticClass:"el-icon-plus"}),_vm._v("新建 ")])]},proxy:true},{key:"table",fn:function(){return [_c('el-table-column',{attrs:{"label":"通知标题","prop":"title"}}),_c('el-table-column',{attrs:{"label":"创建时间","prop":"creationTime"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("formatYearMonthDayDate")(scope.row.creationTime))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{attrs:{"hide-on-click":false},on:{"command":_vm.handleMenu}},[_c('span',{staticClass:"el-dropdown-link"},[_vm._v(" 操作菜单 "),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":{
                  type: 'detail',
                  index: scope.$index,
                  item: scope.row,
                }}},[_vm._v("查看")]),_c('el-dropdown-item',{directives:[{name:"permission",rawName:"v-permission",value:(
                  'Pages.NotifyRecordOrganizationInvestigation:Delete'
                ),expression:"\n                  'Pages.NotifyRecordOrganizationInvestigation:Delete'\n                "}],attrs:{"command":{
                  type: 'delete',
                  index: scope.$index,
                  item: scope.row,
                },"divided":""}},[_vm._v("删除")])],1)],1)]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }