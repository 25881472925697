























































































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";

import {
  ExternalProjectProcessMonitoringDto,
  ExternalProjectProcessMonitoringDtoPagedResultDto,
  AuditFlowScope,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import SendNotify from "@/components/SendNotify/index.vue";
//import IncomeDetail from "@/views/project/externalProjectProcessMonitoring/detail.vue";

@Component({
  name: "ExternalProjectProcessMonitoringList",
  components: {
    SendNotify,
    PagedTableView,
    AgileAuditTag,
    //IncomeDetail
  },
})
export default class ExternalProjectProcessMonitoringList extends Vue {
  @Ref() showNotify!: SendNotify;
  queryForm = {
    displayName: "",
    monitoringType: "",
    title: "",
  };

  editLinkId = 0;
  private currentDetail: ExternalProjectProcessMonitoringDto = {};

  get getNotifyApiUrl() {
    return api.externalProjectProcessMonitoringService.sendNotify;
  }

  // 获取表数据
  fetchData(params: any) {
    return api.externalProjectProcessMonitoringService.getAll(params);
  }

  // 操作按钮
  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;

      case "edit":
        this.handleEdit($row.index, $row.item);
        break;

      case "delete":
        this.handleDelete($row.index, $row.item);
        break;

      case "startAudit":
        this.handleStartAudit($row.index, $row.item);
        break;
      case "auditFlow":
        this.handleAuditFlow($row.index, $row.item);
        break;
    }
  }

  // // 新建
  // handleCreate() {
  //   this.editLinkId = 0;
  //   (this.$refs.editForm as any).show = true;
  // }
  // 发送通知
  handleShowNotify() {
    (this.showNotify as any).isShowNotify = true;
  }

  handleCreate() {
    this.$router.push({
      name: "externalProjectProcessMonitoring-create",
      params: {
        projectId: "0",
      },
    });
  }

  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "externalProjectProcessMonitoring-edit",
      params: {
        id: row.id,
      },
    });
  }

  // // 编辑
  // handleEdit(index: number, row: ExternalProjectProcessMonitoringDto) {
  //   this.editLinkId = row.id!;
  //   (this.$refs.editForm as any).show = true;
  //   (this.$refs.editForm as any).form = row;

  //   console.warn("%cu must reWrite this method", "color:#0048BA;");
  // }

  handleViewDetail(row: ExternalProjectProcessMonitoringDto) {
    this.currentDetail = row;
    (this.$refs.incomeDetail as any).show = true;
  }

  //  提交审核
  handleStartAudit(index: number, row: ExternalProjectProcessMonitoringDto) {
    // api.externalProjectProcessMonitoringService
    //   .startAudit({body: {id: row.id}})
    //   .then(res => {
    //     this.$message.success("已提交");
    //   });
  }

  // //  提交审核
  // handleSendNotify(index: number, row: ExternalProjectProcessMonitoringDto) {
  //   api.externalProjectProcessMonitoringService
  //     .sendNotify({ processMonitorId: row.id })
  //     .then(res => {
  //       this.$message.success("通知已发送，请等候");
  //     });
  // }

  handleDetail(row: any) {
    this.$router.push({
      name: "externalProjectProcessMonitoring-detail",
      params: {
        id: row.id,
      },
    });
  }

  // 删除
  async handleDelete(index: number, row: ExternalProjectProcessMonitoringDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.externalProjectProcessMonitoringService
        .delete({
          id: row.id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.ExternalProjectProcessMonitoring,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }

  handelOnSaved() {
    this.fetchData(this.queryForm);
  }
}
