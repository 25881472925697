


















































import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  NotifyRecordCreateOrUpdateDto,
  NotifyTemplateType,
  NotifyTemplateDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import Ueditor from "@/components/Ueditor/index.vue";

@Component({
  name: "CreateNotifyRecord",
  components: {
    AbSelect,
    ProjectSelect,
    Ueditor,
  },
})
export default class CreateNotifyRecord extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: NotifyRecordCreateOrUpdateDto = {
    hostId: undefined,
    notifyType: undefined,
    notifyTemplateId: undefined,
    title: undefined,
    content: undefined,
    id: 0,
  };
  projectType = false;
  notifyTemplateList: NotifyTemplateDto[] = [];

  async created() {
    switch (this.$route.params.type) {
      case "ProjectAssessmentPlan":
        this.form.notifyType = NotifyTemplateType.ProjectAssessmentPlan;
        break;

      case "OrganizationInvestigation":
        this.form.notifyType = NotifyTemplateType.OrganizationInvestigation;
        break;
    }
    await this.fetchNotifyTemplateList();
  }

  async fetchNotifyTemplateList() {
    api.notifyTemplate
      .getAll({
        notifyTemplateType: this.form.notifyType,
        maxResultCount: 2000,
      })
      .then((res: any) => {
        this.notifyTemplateList = res.items;
      });
  }

  notifyTemplateChange(e: number) {
    api.notifyTemplate.get({ id: e }).then((res) => {
      this.form.content = res.content;
    });
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        // if (this.form!.id) {
        //   await api.notifyRecord.update({
        //     body: this.form
        //   });
        // }
        // else {
        //
        // }
        await api.notifyRecord.create({
          body: this.form,
        });
        this.$router.back();
        this.$message.success("更新成功");
      }
    });
  }

  cancel() {
    this.$router.back();
    // (this.$refs.dataForm as any).resetFields();
    // this.$emit("input", false);
  }

  roleRule = {
    title: [
      {
        required: true,
        message: "通知模板名称必填",
        trigger: "blur",
      },
    ],
    notifyTemplateType: [
      {
        required: true,
        message: "通知模板类型必填",
        trigger: "blur",
      },
    ],
  };
}
