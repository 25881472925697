












































































































































































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  ExternalProjectCreateOrUpdateDto,
  ExternalProjectSpecificPlanDto,
  ProgressReportDetailDto,
  ProjectMemberItemDto,
  ProjectRiskPlanDto,
  ProjectSpecificGoalDto,
  ExternalProjectDetailDto,
  AuditFlowScope,
  AttachmentHostType,
  DataDictionaryDto,
  ExternalProjectBudgetItemDto,
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

import AttachmentsView from "@/components/AttachmentsView/index.vue";

@Component({
  name: "progressReportDetail",
  components: {
    AttachmentsView,
    ExportWord,
    AuditBlock,
  },
  filters: {
    formatSpecificGoal(
      goalId: number | string,
      effects: ProjectSpecificGoalDto[]
    ) {
      let specificGoal: string | undefined;
      if (effects && effects.length > 0) {
        effects.map((item: ProjectSpecificGoalDto) => {
          if (item.id == goalId) {
            specificGoal = item.effect;
            return;
          }
        });
      }
      return specificGoal;
    },
    formatBudgetCategory(
      budgetCategory: string,
      projectBudgetCategoryList: any[]
    ) {
      let budget: string | undefined;
      if (projectBudgetCategoryList && projectBudgetCategoryList.length > 0) {
        projectBudgetCategoryList.map((item: any) => {
          if (item.value == budgetCategory) {
            budget = item.text;
            return;
          }
        });
      }
      return budget;
    },
    formatEducation(value: number, list: any[]) {
      let name: string | undefined;
      if (list && list.length > 0) {
        list.map((item: any) => {
          if (item.id == Number(value)) {
            name = item.displayName;
            return;
          }
        });
      }
      return name;
    },
  },
})
export default class ProgressReportDetail extends Vue {

  externalProjectId = 0;
  // 具体计划
  specificPlans: ExternalProjectSpecificPlanDto[] = [
    {
      // projectId: undefined,
      projectSpecificGoalId: undefined,
      title: undefined,
      startDate: undefined,
      endDate: undefined,
      activityCount: undefined,
      peopleCount: undefined,
      output: undefined,
    },
  ];
  specificPlansData: any[] = [
    {
      projectSpecificGoalId: 0,
      data: [],
    },
  ];
  afterProjectBudgetCategoryList: any[] = [
    {
      projectBudgetCategory: 0,
      data: [],
    },
  ];
  afterProjectBudgetTypeList: any[] = [
    {
      projectBudgetType: 0,
      data: [],
    },
  ];
  afterItemNameList: any[] = [
    {
      itemName: 0,
      data: [],
    },
  ];
  // 项目成效及成功指标
  effects: ProjectSpecificGoalDto[] = [
    {
      effect: undefined,
      norm: undefined,
      validateCondition: undefined,
    },
  ];
  // 项目风险预案
  riskPlans: ProjectRiskPlanDto[] = [
    {
      risk: undefined,
      copingStrategy: undefined,
    },
  ];
  // 项目团队信息
  projectMemberItems: ProjectMemberItemDto[] = [
    {
      name: undefined,
      educationTypeId: undefined,
      major: undefined,
      projectRole: undefined,
      inputPercent: undefined,
    },
  ];

  detail: ExternalProjectDetailDto = {
    specificPlans: this.specificPlans,
    effects: this.effects,
    riskPlans: this.riskPlans,
    projectMemberItems: this.projectMemberItems,
    projectMangerInfo: undefined,
    id: 0,
  };


  // 项目预算种类 一级
  projectBudgetCategoryList = [];

  // 项目预算种类 二级
  projectBudgetTypeList = [];

  // 资金来源
  projectBudgetCapitalSourceList = [];

  selector = "proposal-detail";
  filename = "项目计划书";
  styles = "";

  // 学历
  educationList: DataDictionaryDto[] = [];

  get hostTypeFile() {
    return AttachmentHostType.External_Project;
  }

  setTable(tableData: ExternalProjectBudgetItemDto[]) {
    let spanOneArr: any[] = [],
      spanTwoArr: any[] = [],
      spanThreeArr: any[] = [],
      concatOne = 0,
      concatTwo = 0,
      concatThree = 0;
    tableData.forEach((item, index) => {
      if (index === 0) {
        spanOneArr.push(1);
        spanTwoArr.push(1);
        spanThreeArr.push(1);
      } else {
        if (
          item.projectBudgetCategory ===
          tableData[index - 1].projectBudgetCategory
        ) {
          //第一列需合并相同内容的判断条件
          spanOneArr[concatOne] += 1;
          spanOneArr.push(0);
        } else {
          spanOneArr.push(1);
          concatOne = index;
        }
        if (item.projectBudgetType === tableData[index - 1].projectBudgetType) {
          //第二列和需合并相同内容的判断条件
          spanTwoArr[concatTwo] += 1;
          spanTwoArr.push(0);
        } else {
          spanTwoArr.push(1);
          concatTwo = index;
        }

        if (item.itemName === tableData[index - 1].itemName) {
          //第三列和需合并相同内容的判断条件
          spanThreeArr[concatThree] += 1;
          spanThreeArr.push(0);
        } else {
          spanThreeArr.push(1);
          concatThree = index;
        }
      }
    });
    return {
      one: spanOneArr,
      two: spanTwoArr,
      three: spanThreeArr,
    };
  }

  objectSpanMethod(item: any) {
    let { row, column, rowIndex, columnIndex } = item;

    const _row = this.setTable(this.detail.budgetItems!);
    if (columnIndex === 0) {
      const _col = _row.one[rowIndex] > 0 ? 1 : 0;
      return {
        rowspan: _row.one[rowIndex],
        colspan: _col,
      };
    }

    if (columnIndex === 1) {
      const _col = _row.two[rowIndex] > 0 ? 1 : 0;
      return {
        rowspan: _row.two[rowIndex],
        colspan: _col,
      };
    }

    if (columnIndex === 2) {
      const _col = _row.three[rowIndex] > 0 ? 1 : 0;
      return {
        rowspan: _row.three[rowIndex],
        colspan: _col,
      };
    }
  }

  created() {
    this.fetchEnum();
    this.getEducationList();
    if (this.$route.params.id) {
      this.externalProjectId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }


  get auditApi(){
    return {
      canAudit: api.externalProject.canAudit,
      userAudit: api.externalProject.audit
    } as AuditApi
  }

  // 获取详情
  async fetchDetail() {
    await api.externalProject
      .getDetail({ id: this.externalProjectId })
      .then((res) => {
        this.detail = { ...res };


        // 项目具体计划

        if (res.specificPlans && res.specificPlans.length > 0) {
          let afterSpecificPlans: any[] = [];
          res.specificPlans!.forEach((item) => {
            let beforeSpecificPlans = afterSpecificPlans.find(
              (item1) =>
                item1.projectSpecificGoalId === item.projectSpecificGoalId
            );
            if (!beforeSpecificPlans) {
              afterSpecificPlans.push({
                projectSpecificGoalId: item.projectSpecificGoalId,
                data: [item],
              });
            } else {
              beforeSpecificPlans.data.push(item);
            }
          });
          this.specificPlansData = afterSpecificPlans;
        }

        // 项目预算
        if (res.budgetItems && res.budgetItems.length > 0) {
          let afterProjectBudgetCategory: any[] = []; //预算科目

          let afterProjectBudgetType: any[] = []; //预算类型
          let afterItemName: any[] = []; //子项目（活动）内容

          res.budgetItems!.forEach((item) => {
            let beforeProjectBudgetCategory = afterProjectBudgetCategory.find(
              (item1) =>
                item1.projectBudgetCategory === item.projectBudgetCategory
            );
            let beforeProjectBudgetType = afterProjectBudgetType.find(
              (item1) => item1.projectBudgetType === item.projectBudgetType
            );
            let beforeItemName = afterItemName.find(
              (item1) => item1.itemName === item.itemName
            );

            if (!beforeItemName) {
              afterItemName.push({
                itemName: item.itemName,
                data: [item],
              });
            } else {
              beforeItemName.data.push(item);
            }

            if (!beforeProjectBudgetType) {
              afterProjectBudgetType.push({
                projectBudgetType: item.projectBudgetType,
                data: [item],
              });
            } else {
              beforeProjectBudgetType.data.push(item);
            }
            //
            //
            if (!beforeProjectBudgetCategory) {
              afterProjectBudgetCategory.push({
                projectBudgetCategory: item.projectBudgetCategory,
                data: [item],
              });
            } else {
              beforeProjectBudgetCategory.data.push(item);
            }
          });
          this.afterProjectBudgetCategoryList = afterProjectBudgetCategory;
          this.afterProjectBudgetTypeList = afterProjectBudgetType;
          this.afterItemNameList = afterItemName;
          console.log(this.afterItemNameList);
        }
      });
  }

  fetchEnum() {
    api.enumService
      .getValues({ typeName: "ProjectBudgetCategory" })
      .then((res: any) => {
        this.projectBudgetCategoryList = res;
      });
    api.enumService
      .getValues({ typeName: "ProjectBudgetType" })
      .then((res: any) => {
        this.projectBudgetTypeList = res;
      });
    api.enumService
      .getValues({ typeName: "ProjectBudgetCapitalSource" })
      .then((res: any) => {
        this.$nextTick(() => {
          this.projectBudgetCapitalSourceList = res;
        });
      });
  }

  getEducationList() {
    api.dataDictionary
      .getDataDictionaryByKey({ key: "EducationType" })
      .then((res: any) => {
        this.$nextTick(() => {
          this.educationList = res;
        });
      });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
