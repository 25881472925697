





















































































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AttachmentDto,
  AttachmentHostType,
  AuditFlowScope,
  ExternalProjectIntentionCreateOrUpdateDto,
} from "@/api/appService";
import api from "@/api";
import ExportWord from "@/components/DownloadWord/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";


@Component({
  name: "externalProjectLetterIntentDetail",
  components: {
    ExportWord,
    AttachmentsView,
    AuditBlock,
  },
})
export default class externalProjectLetterIntentDetail extends Vue {

  dataId?: number;
  detail: ExternalProjectIntentionCreateOrUpdateDto = {};
  attachmentList: AttachmentDto[] = [];
  selector = "table-detail-world";
  filename = "项目意向书";
  styles = "";
  hostType = AttachmentHostType.External_Project_Intention;


  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      this.fetchDetail();
      this.getAttachments();
    }
  }

  fetchData() {
    return api.externalProjectIntention.audit;
  }

  get auditApi(){
    return {canAudit: api.externalProjectIntention.canAudit,userAudit: api.externalProjectIntention.audit} as AuditApi
  }

  // 获取详情
  async fetchDetail() {
    await api.externalProjectIntention
      .getDetail({ id: this.dataId })
      .then((res) => {
        this.detail = { ...res };
      });
  }

  // 获取附件
  async getAttachments() {
    await api.attachmentService
      .getAttachments({
        hostId: this.dataId!.toString(),
        hostType: AttachmentHostType.External_Project_Intention,
      })
      .then((res) => {
        this.attachmentList = res;
      });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
