




























































































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import moment from "moment";
import { ProgressReportDto, AuditFlowScope } from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import SendNotify from "@/components/SendNotify/index.vue";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "projectProgressReport",
  components: {
    SendNotify,
    PagedTableView,
    AgileAuditTag,
  },
})
export default class ProjectProgressReportList extends Vue {
  @Ref() showNotify!: SendNotify;

  tableItems: ProgressReportDto[] = [];
  queryForm: any = {
    startDate: undefined,
    endDate: undefined,
    organizationUnitName: undefined,
    projectName: undefined,
  };
  notifyProjectId: number | string = "";

  get getNotifyApiUrl() {
    return api.externalProjectProcessReport.sendNotify;
  }

  created() {
    if (this.$route.query.projectId) {
      this.queryForm.projectId = Number(this.$route.query.projectId);
    }
  }

  fetchData(params: any) {
    return api.externalProjectProcessReport.getAll(params);
  }

  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;

      case "edit":
        this.handleEdit($row.index, $row.item);
        break;

      case "delete":
        this.handleDelete($row.index, $row.item);
        break;

      case "startAudit":
        this.handleStartAudit($row.index, $row.item);
        break;

      case "auditFlow":
        this.handleAuditFlow($row.index, $row.item);
        break;

      case "notice":
        this.handleSendNotify($row.index, $row.item);
        break;
    }
  }
  // 发送通知
  handleShowNotify() {
    (this.showNotify as any).isShowNotify = true;
  }

  handleCreate() {
    this.$router.push({ name: "externalProject-progressReports-create" });
  }

  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "externalProject-progressReports-edit",
      params: { id: row.id!.toString() },
    });
  }

  handleDetail(row: ProgressReportDto) {
    this.$router.push({
      name: "externalProject-progressReports-detail",
      params: { id: row.id!.toString() },
    });
  }

  //  提交审核
  async handleStartAudit(index: number, row: ProgressReportDto) {
    // await api.externalProjectProcessReport.startAudit({body: {id: row.id}}).then(res => {
    //   this.$message.success("已提交");
    // });
  }

  // 删除
  async handleDelete(index: number, row: ProgressReportDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.externalProjectProcessReport
        .delete({ id: row.id })
        .then((res: any) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.ExternalProjectProgressReport,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }

  handleSendNotify(index: number, row: ProgressReportDto) {
    this.$confirm("你确定发送通知吗?", "提示").then(async () => {
      api.externalProjectProcessReport
        .sendNotify({ projectId: row.id })
        .then((res: any) => {
          this.$message({
            type: "success",
            message: "发送成功!",
          });
        });
    });
  }
}
