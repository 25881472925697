
















































































































































































































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import {
  AttachmentHostType,
  AuditFlowScope,
  DataDictionaryDto,
  ProgressReportDetailDto,
} from "@/api/appService";
import ExportWord from "@/components/DownloadWord/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";
import api from "@/api";
import moment from "moment";


@Component({
  name: "progressReportDetail",
  components: {
    ExportWord,
    AttachmentsView,
    AuditBlock,
  },
  filters: {
    formatEducationType(
      educationTypeId: number | string,
      effects: DataDictionaryDto[]
    ) {
      let specificGoal: string | undefined;
      if (effects && effects.length > 0) {
        effects.map((item: DataDictionaryDto) => {
          if (item.id == educationTypeId) {
            specificGoal = item.displayName;
            return;
          }
        });
      }
      return specificGoal;
    },
  },
})
export default class ProgressReportDetail extends Vue {

  progressReportId?: number;
  detail: ProgressReportDetailDto = {};
  selector = "table-detail";
  filename = "中期总结报告表";
  styles = "";
  hostType = AttachmentHostType.External_Project_Progress_Report;
  externalProject: any = {
    projectPeriod: undefined,
    objectiveOfMain: undefined,
    serviceGroup: undefined,
  };

  projectMembers: any = [
    {
      name: undefined,
      age: undefined,
      position: undefined,
      qualification: undefined,
      team: undefined,
      remark: undefined,
    },
  ];

  projectSpecificGoals: any = [
    {
      effect: undefined,
      norm: undefined,
      validateCondition: undefined,
      status: undefined,
    },
  ];

  budgetUsed: any = [
    {
      subject: undefined,
      planAmount: undefined,
      actualAmount: undefined,
      remark: undefined,
    },
  ];

  problems: any = [
    {
      description: undefined,
      reason: undefined,
      solution: undefined,
    },
  ];
  educationTypeList: DataDictionaryDto[] = [];


  created() {
    if (this.$route.params.id) {
      this.progressReportId = Number(this.$route.params.id);
      this.fetchDetail();
      this.fetchDataDictionary();
    }
  }

  fetchDataDictionary() {
    api.dataDictionary
      .getDataDictionaryListByKey({
        key: "EducationType",
        maxResultCount: 1000,
      })
      .then((res: any) => {
        this.educationTypeList = res.items;
      });
  }


  get auditApi(){
    return {
      canAudit: api.externalProjectProcessReport.canAudit,
      userAudit: api.externalProjectProcessReport.audit
    } as AuditApi
  }

  // 获取详情
  async fetchDetail() {
    await api.externalProjectProcessReport
      .getDetail({ id: this.progressReportId })
      .then((res) => {
        this.detail = { ...res };
        this.projectMembers = JSON.parse(this.detail.projectMemberJson!);
        this.projectSpecificGoals = JSON.parse(
          this.detail.projectSpecificGoalJson!
        );
        this.budgetUsed = JSON.parse(this.detail.budgetUsedJson!);
        this.problems = JSON.parse(this.detail.problemsJson!);


        if (this.detail && this.detail.projectId) {
          api.externalProject
            .getDetailByProjectId({ projectId: this.detail.projectId })
            .then((res: any) => {
              this.externalProject.projectPeriod =
                moment(String(res.startDate)).format("YYYY-MM-DD") +
                " - " +
                moment(String(res.endDate)).format("YYYY-MM-DD");
              this.externalProject.objectiveOfMain = res.objectiveOfMain;
              this.externalProject.serviceGroup =
                res.benefitObjectOfDirect! + "," + res.benefitObjectOfIndirect!;
            });
        }
      });
  }


  // 返回
  private cancel() {
    this.$router.back();
  }
}
