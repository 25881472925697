





















































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import moment from "moment";
import {
  AuditFlowScope,
  // AuditUserLogType,
  ExternalProjectIntentionDto,
} from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import AuditFlowDetailButton from "@/components/AuditFlowDetailButton/index.vue";

@Component({
  name: "externalProjectLetterIntentList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
    AuditFlowDetailButton,
  },
})
export default class externalProjectLetterIntentList extends Vue {
  queryForm = {
    displayName: undefined,
  };

  fetchData(params: any) {
    return api.externalProjectIntention.getAll(params);
  }

  // 操作按钮
  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;

      case "edit":
        this.handleEdit($row.index, $row.item);
        break;

      case "delete":
        this.handleDelete($row.index, $row.item);
        break;

      case "startAudit":
        this.handleStartAudit($row.index, $row.item);
        break;

      case "auditFlow":
        this.handleAuditFlow($row.index, $row.item);
        break;
    }
  }

  handleCreate() {
    this.$router.push({
      name: "monthlyPlan-create",
    });
  }

  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "externalProjectLetterIntent-edit",
      params: {
        id: row.id,
      },
    });
  }

  handleDetail(row: any) {
    this.$router.push({
      name: "externalProjectLetterIntent-detail",
      params: {
        id: row.id,
      },
    });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.ExternalProjectIntention,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }

  //  提交审核
  async handleStartAudit(index: number, row: ExternalProjectIntentionDto) {
    // await api.externalProjectIntention.startAudit({body: {id: row.id}}).then(res => {
    //   this.$message.success("已提交");
    // });
  }

  // 删除
  async handleDelete(index: number, row: ExternalProjectIntentionDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.externalProjectIntention.delete({ id: row.id }).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }
}
